<script setup lang="ts">
useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | Laoshi` : 'Laoshi'
  }
})
</script>
<template>
  <div>
    <slot />
  </div>
</template>
